import styled from 'styled-components'

export const StyledProductTerms = styled.div`
  .terms-title {
    font-size: ${(props) => props.theme.v2.typography.label.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.label.m.lineHeight};
    margin: 0px 0 8px;
  }

  ul.product-terms {
    list-style-type: disc;
    padding-left: 0;
    margin: 0;

    li {
      display: list-item;
      font-size: ${(props) => props.theme.v2.typography.label.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.label.m.lineHeight};
      margin-bottom: 8px;
      margin-left: 16px;
    }

    a {
      color: ${(props) => props.theme.v2.interactive.bright};
      &:hover {
        color: ${(props) => props.theme.v2.interactive.subdued};
      }
    }
  }
`

export const StyledProductTermsV2 = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${(props) => props.theme.v2.surface.cream500};
  padding: 38px;
  flex-wrap: wrap;
  width: 100vw;

  .terms-wrapper {
    max-width: 100vw;
  }

  .terms-title {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    color: ${(props) => props.theme.colours.text.h1};
    font-weight: 300;
    line-height: 30px;
    margin: 0px 0 8px;
  }

  .terms-subtitle {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
    font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    color: ${(props) => props.theme.colours.text.h2};
    font-weight: 600;
    line-height: 30px;
    margin: 0px 0 8px;
  }

  ul.product-terms {
    list-style-type: disc;
    padding-left: 0;
    margin: 0;
    max-width: 820px;

    li {
      display: list-item;
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
      margin-bottom: 8px;
      margin-left: 40px;
    }

    a {
      color: ${(props) => props.theme.v2.interactive.bright};
      &:hover {
        color: ${(props) => props.theme.v2.interactive.subdued};
      }
    }
  }
`
