import { CHECKOUT_US, TermsProps } from '@/lib/checkout/types'
import { formatCurrency } from '@/lib/checkout/utils'

export const MembershipTerms = ({
  country,
  currency,
  price,
  priceAfterDiscount,
  productDuration,
  showTitle,
  title = 'Membership agreement',
}: TermsProps) => {
  const priceIncludingDiscount = priceAfterDiscount ?? price
  const isUSCustomer = country === CHECKOUT_US
  const daysBeforeReminderEmail = 15
  const billingInterval =
    productDuration?.intervalCount === 1 ? productDuration?.interval : `${productDuration?.interval}s`
  const isMonthlyMembership = productDuration?.interval === 'month' && productDuration?.intervalCount === 1

  return (
    <>
      <div className="terms-wrapper">
        {showTitle && (
          <p className="terms-title" data-testid="membership-terms-title">
            {title}
          </p>
        )}
        <ul className="product-terms">
          <li>
            Your membership will start when we deliver your test results. We will bill your card{' '}
            {formatCurrency(currency, priceIncludingDiscount)} {isUSCustomer && '(plus applicable sales tax)'} on this
            date.
          </li>
          <li data-testid="membership-terms-billing-frequency">
            Your membership will continue until you choose to cancel it. We will bill your card every{' '}
            {productDuration?.intervalCount} {billingInterval}.
          </li>
          {!isMonthlyMembership && (
            <li>We will send you a reminder email {daysBeforeReminderEmail} days before your membership renews. </li>
          )}
          <li>You can cancel your membership anytime before the next payment by using our app.</li>
          <li>
            If you cancel before the end of your membership we will not provide a refund or credit for partial months.
          </li>
        </ul>
      </div>
    </>
  )
}
