import { DSText } from '@zoe/ds-web'

interface DSFormErrorTextProps {
  children: React.ReactNode
}

export const DSFormErrorText = ({ children }: DSFormErrorTextProps) => {
  return (
    <DSText variant="fluid-label-100" weight="lotaLight" colour="error">
      {children}
    </DSText>
  )
}
