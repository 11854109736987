import { TermsProps } from '@/lib/checkout/types'
import { StyledProductTerms } from './TermStyles'

export const GutHealthRetestTerms: React.FC<TermsProps> = ({ showTitle, title }) => {
  return (
    <StyledProductTerms>
      {showTitle && (
        <p className="terms-title" data-testid="membership-terms-title">
          {title}
        </p>
      )}
      <ul className="product-terms">
        <li>
          We&apos;ll ship your retest kit as soon as you&apos;ve been a ZOE member for 4 months. It may take 2-4 weeks
          from this date.
        </li>
        <li>
          In order to see the best results, make sure you eat the ZOE way for most of your meals for 2 months before
          testing.
        </li>
        <li>You must remain a ZOE member - with an active membership - until your retest results are ready.</li>
        <li>
          Please note that antibiotics damage your gut microbiome and can worsen results. If you are taking antibiotics,
          you can still buy the test, but consider delaying when you take the test until 60-90 days after you finish
          your antibiotics.
        </li>
        <li>
          Many factors like location, household changes, new pets and recent health influence gut health. While diet
          plays a major role, your gut health score depends on various factors.
        </li>
        <li>
          If you have any questions, you can contact us using the in-app chat or emailing us on{' '}
          <a href="mailto:hello@joinzoe.com" target="_blank" rel="noreferrer">
            hello@joinzoe.com
          </a>
          .
        </li>
      </ul>
    </StyledProductTerms>
  )
}
