import { TermsProps } from '@/lib/checkout/types'
import { formatCurrency } from '@/lib/checkout/utils'

// TODO: delete this if we stop doing free trial
export const MembershipStarterFreeTrialTerms = ({
  currency,
  price,
  showTitle,
  title,
  priceAfterDiscount,
  payNowAmount,
}: TermsProps) => {
  const priceIncludingDiscount = priceAfterDiscount ?? price
  const displayAmount = formatCurrency(currency, priceIncludingDiscount, true)
  const displayPayNowAmount = formatCurrency(currency, payNowAmount, true)
  return (
    <>
      <div className="terms-wrapper">
        {showTitle && (
          <p className="terms-title" data-testid="membership-terms-title">
            {title}
          </p>
        )}
        <ul className="product-terms">
          <li>Your 14-day free trial starts starts today. </li>
          <li>Your kit should arrive within 3 working days.</li>
          <li>
            We’ll charge you {displayPayNowAmount} after 14 days, unless you have cancelled during your free trial.
          </li>
          <li>
            Your first membership payment of {displayAmount} will be a month after your {displayPayNowAmount} payment.
          </li>
          <li>As part of your 4-month membership, we’ll charge you {displayAmount} per month for 4 months.</li>
          <li>Your membership will renew for another 4 months unless you cancel it.</li>
          <li>
            We don’t provide refunds or credit for partial months if you cancel before the end of your membership.
          </li>
        </ul>
      </div>
    </>
  )
}
